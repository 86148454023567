/**
 * These theme values will override the base theme.
 *
 * We're passing these values to Layout component;
 * The Layout component merges these values with the
 * base theme.
 *
 */

const pink = {
  100: '#059AE5',
  200: '#059AE5',
  300: '#059AE5',
  400: '#005BD2',
  500: '#005BD2',
  600: '#005BD2',
  700: '#243A8C',
  800: '#243A8C',
  900: '#243A8C'
}

const orange = {
  100: '#01E7BE',
  200: '#01E7BE',
  300: '#01E7BE',
  400: '#03ACB7',
  500: '#03ACB7',
  600: '#03ACB7',
  700: '#056DB0',
  800: '#056DB0',
  900: '#056DB0'
}

export default {
  colors: {
    betaLighter: pink[100],
    betaLight: pink[300],
    beta: pink[500],
    betaDark: pink[700],
    betaDarker: pink[800],
    alphaLighter: orange[100],
    alphaLight: orange[300],
    alpha: orange[500],
    alphaDark: orange[600],
    alphaDarker: orange[800],
    background: `#FCFCFF`,
    headerBg: `transparent`,
    footerBg: `#F6F7FA`
  }
}
