import React,{useEffect} from 'react'
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from '@flexiblocks/templates/saas-v2/_theme'
import styles from '@flexiblocks/templates/saas-v2/_index.styles'
import { useQueryParam, StringParam } from "use-query-params";
import axios from 'axios';

const SuccessPage = props => {
  const { allBlockContent } = useStaticQuery(query)
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)
  const sessionid = useQueryParam("session_id", StringParam);
  
  useEffect(()=>{
    var entry = {
      session:sessionid[0],
      time:new Date().toISOString()
    };
    if(typeof sessionid[0] !== 'undefined'){
      axios.post(`http://email.rhodium11.com/purchasetaken`, entry)
      .then(res => {
      });
      navigate("/success")
    }
  },[sessionid]);

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Rhodium11' />
      {/* Blocks */}
      <Header content={content['header1']} menuJustify='space-between' />
      <Divider space='5' />
      <Container sx={styles.tabsContainer}>
        <h1 css={{textAlign:"center",fontsize:"64px"}}>Thank you for your purchase. An admin has been notified and will be in touch with you shortly.</h1>
        <h4 css={{textAlign:"center"}}><a href="/" css={{padding: "20px 40px",background: "#3dcfbe",color: "#fff",borderRadius: "30px",textDecoration: "none",marginTop:"20px"}}>Go back Home</a></h4>
      </Container>
    </Layout>
  )
}

const query = graphql`
  query siteSuccessBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/index", "site/shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default SuccessPage
